@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Noto+Serif+Gujarati:wght@100..900&family=Roboto+Slab:wght@100..900&family=Tiro+Devanagari+Marathi:ital@0;1&display=swap");
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Lora", "Tiro Devanagari Marathi", "Noto Serif Gujarati", serif;
  /* font-family: "Lora", "Noto Serif Gujarati", serif; */
  /* font-family: "Tiro Devanagari Marathi", serif;
  font-family: "Noto Serif Gujarati", serif; */
}
/* #root {
  max-width: 96vw;
  max-height: 100vh;
} */
