.columnFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.rowFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.rowFlexEvenly,
.evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}
.rowFlexAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.rowFlexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.wrapFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
