.height_full {
  height: 100%;
}
.submitBtnParagraphOfCreateTestSeriesPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 20px;
  /* z-index: 1000; */
}
.createTestSeriesPopupFormDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  /* height: calc(100% - 20px); */
  overflow: auto;
}
.createTestSeriesPopupFormDiv input {
  margin: 10px;
}
.createTestSeriesPopupFormDiv input[type="text"],
.createTestSeriesPopupFormDiv input[type="textarea"],
.createTestSeriesPopupFormDiv input[type="number"] {
  /* width: 100%; */
  height: 40px;
  background: transparent;
  /* border: none; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px #fff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 5px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  outline: none;
  box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset; */
  /* padding: 5px 10px;
  border: 1px solid rgba(137, 138, 137);
  border-radius: 5px;
  outline: none; */
}
.createTestSeriesPopupFormDiv input[type="text"]:focus {
  /* border-color: #0366d6;
  outline: none;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px; */
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.05), inset -5px -5px 10px #fff;
  border: 1px solid rgba(0, 0, 0);
}
/* .createTestSeriesPopupFormDiv_singleInputDiv {
  display: flex;
  justify-content: center;
  align-self: center;
} */
/* .createTestSeriesPopupFormDiv_singleInputDiv label {
  font-size: 1.2rem;
  font-weight: 900;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  color: rgb(0, 0, 0);
} */
.inputTypeDateClass {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.inputTypeTextArea {
  padding: 10px;
  width: 500px;
  height: 150px;
  overflow-y: scroll;
}
.createTestSeries_priceDistributionMainDiv,
.createTestSeries_testSeriesSectionsMainDiv {
  margin: 15px;
  width: calc(100% - 40px);
  padding: 10px 30px;
  border: 1px dotted rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.createTestSeries_priceDistributionFirstDiv,
.createTestSeries_testSeriesSectionsFirstDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.createTestSeries_priceDistributionInputDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow-x: auto;
}
.createTestSeries_priceDistributionEntriesDiv {
  max-height: 200px;
  overflow: auto;
  margin-top: 10px;
}
.createTestSeries_priceDistributionSelect,
.inputSelect {
  width: 120px;
  height: 40px;
  padding: auto 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.createTestSeries_priceDistributionDeleteOne {
  color: red;
  margin: 5px;
  width: 25px;
  height: 25px;
  min-width: 22px;
  cursor: pointer;
}
.createTestSeries_sectionsInnerMainDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.createTestSeries_singleSectionDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
