.focus_lightGreen {
  background-color: rgba(0, 128, 0, 0.2);
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.pointer {
  cursor: pointer;
}
