/* .jodit-react-container .jodit-wysiwyg {
  padding: 0 30px;
}
   .democlass {
  background-color: red;
} */

.joditEditor table {
  border-collapse: collapse;
}

.joditEditor td,
.joditEditor th {
  border: 1px solid rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.joditEditor ol,
.joditEditor ul {
  margin-left: 20px;
}

.editor-content {
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}
