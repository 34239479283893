.testSeriesPage_firstSection {
  border: 1px solid rgba(1, 1, 1, 0.5);
  border-radius: 5px;
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-wrap: wrap;
}
.testSeriesPage_firstSection .testSeriesPage_img {
  max-width: 300px;
  width: 90%;
  border-radius: 5px;
  /* height: 200px; */
  height: auto;
  margin: 10px;
  box-shadow: 5px 5px 10px rgba(1, 1, 1, 0.25),
    -5px -5px 10px rgba(1, 1, 1, 0.25);
}
.testSeriesPage_firstSection .testSeriesPage_1s_1d {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}
.testSeriesPage_firstSection .testSeriesPage_1s_1d p {
  font-size: 18px;
  font-weight: 600;
}

.testSeriesPage_secondSection {
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  border: 1px solid rgba(1, 1, 1, 0.5);
  border-radius: 5px;
}
.testSeriesPage_secondSection .testSeriesPage_2s_1p {
  font-size: 25px;
  font-weight: 600;
  /* text-decoration: underline; */
}
.testSeriesPage_2s_titleDiv {
  height: 40px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: space-evenly;
  gap: 0;
  align-items: center;
}
.testSeriesPage_2s_titleDiv .testSeriesPage_2s_singleTitle {
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  cursor: pointer;
}
.testSeriesPage_2s_titleDiv .singleTitle_isActive {
  /* background-color: #53ff8c; */
  color: rgb(0, 118, 0);
  background: linear-gradient(
    0deg,
    rgba(108, 255, 87, 1) 0%,
    rgba(167, 254, 157, 1) 23%,
    rgba(206, 255, 208, 1) 100%
  );
  border-bottom: 2px solid rgb(0, 81, 0);
}

.testSeriesPage_secondSection .testSeriesPage_2s_detailsDiv {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

/* single test series design importing. */
.allTestsUnderSingleSectionMainDiv-singleTestSeriesAllTests {
  max-width: 90%;
  width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;
}

.singleTestMainDiv-singleTestSeriesAllTests {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  border: black 1px solid;
  border-radius: 5px;
}
.singleTestTopDiv-singleTestSeriesAllTests {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 60px;
  border-bottom: silver 1px solid;
}
.testName-singleTestSeriesAllTests {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.rightSideOfTestName-singleTestSeriesAllTests {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-evenly;
  gap: 2px;
}
.free-giveTestButton-singleTestSeriesAllTests {
  width: max-content;
  background-color: #0dfa28;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  /* color: white; */
}
.giveTestButton-singleTestSeriesAllTests {
  width: max-content;
  background-color: #f91303;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  color: white;
}

.singleTestSecondDiv-singleTestSeriesAllTests {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  padding: 0 10px;
  height: 30px;
  font-size: 12px;
}
