/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* design for jodit text editor output */
.showRichText table {
  border-collapse: collapse;
}

.showRichText td,
.showRichText th {
  border: 1px solid rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.showRichText ol,
.showRichText ul {
  margin-left: 20px;
}
.showRichText img {
  max-width: 90%;
}

/* .editor-content {
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
} */

.simpleBtn {
  background-color: rgba(225, 225, 225, 0.4);
  font-weight: 600;
  padding: 5px 10px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 5px;
  cursor: pointer;
}
.simpleBtn:hover {
  background-color: rgba(225, 225, 225, 0.8);
}
.simpleBtn:active {
  scale: 0.95;
}
.simpleBorderBtn {
  background-color: rgba(225, 225, 225, 0.4);
  font-weight: 600;
  padding: 5px 10px;
  border: 1px solid rgb(56, 56, 56);
  border-radius: 5px;
  cursor: pointer;
}
.simpleBorderBtn:hover {
  background-color: rgba(225, 225, 225, 0.8);
}
.simpleBorderBtn:active {
  scale: 0.95;
}

.primaryBtn {
  background-color: rgba(0, 255, 0, 0.25);
  /* background-color: #b7b1f8; */
  color: green;
  border: 1px solid green;
  /* border: none; */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 5px 5px 15px rgba(0, 128, 0, 0.1),
    -5px -5px 15px rgba(0, 128, 0, 0.1);
}
.primaryBtn:hover {
  background-color: rgba(0, 255, 0, 0.35);
  /* background-color: #b7b1f8; */
  /* color: white; */
  box-shadow: inset 5px 5px 15px rgba(0, 128, 0, 0.3),
    inset -5px -5px 15px rgba(0, 128, 0, 0.3);
}
.primaryBtn:active {
  scale: 0.95;
}
.dangerBtn {
  background-color: rgba(255, 0, 0, 0.25);
  /* background-color: #b7b1f8; */
  color: red;
  border: 1px solid red;
  /* border: none; */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 5px 5px 15px rgba(128, 0, 0, 0.2),
    -5px -5px 15px rgba(128, 0, 0, 0.2);
}
.dangerBtn:hover {
  background-color: rgba(255, 0, 0, 0.35);
  /* background-color: #b7b1f8; */
  /* color: white; */
  box-shadow: inset 5px 5px 15px rgba(128, 0, 0, 0.3),
    inset -5px -5px 15px rgba(128, 0, 0, 0.3);
}
.dangerBtn:active {
  scale: 0.95;
}

.priceSavePercentage {
  /* animation-name: opacity_with_scale;
  animation-duration: 2s;
  animation-iteration-count: infinite; */
  animation: opacity_with_scale 2s infinite;
  min-width: 200px;
  text-align: center;
  font-weight: 600;
  /* display: flex;
  justify-content: center; */
}
@keyframes opacity_with_scale {
  0% {
    opacity: 1;
    transform: scale(1);
    /* font-weight: 600; */
  }
  50% {
    opacity: 0.2;
    transform: scale(1.5);
    /* font-weight: 400; */
  }
  100% {
    opacity: 1;
    transform: scale(1);
    /* font-weight: 600; */
  }
}

.globalPopupMainDiv {
  z-index: 10;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.globalPopupFormMainDiv {
  padding: 20px;
  border-radius: 5px;
  background: white;
  max-width: 90%;
  max-height: 90%;
}
.globalPopupChildrenDiv {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
}

.globalFormDiv {
  max-height: calc((100vh - 60px) * 0.9 - 90px);
  overflow-y: auto;
}
.globalPopupForm {
  /* max-height: calc((100vh - 60px) * 0.9 - 80px); */
  /* overflow-y: auto; */
  padding: 5px;
}

.iconifyRedIcon {
  color: red;
  margin: 5px;
  width: 25px;
  height: 25px;
  min-width: 22px;
  cursor: pointer;
}
.iconifyGreenIcon {
  color: green;
  margin: 5px;
  width: 25px;
  height: 25px;
  min-width: 22px;
  cursor: pointer;
}

.overflowXAuto {
  overflow-x: auto;
}
.overflowYAuto {
  overflow-y: auto;
}
.boldFont {
  font-weight: bold;
}

.globalPopupForm input[type="text"],
.globalPopupForm input[type="number"],
.globalPopupForm input[type="email"],
.globalPopupForm input[type="password"] {
  height: 40px;
  background: transparent;
  /* border: none; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px #fff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
