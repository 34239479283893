.p-5 {
  padding: 5px;
}

.maxHeightFull {
  max-height: 100%;
}
.maxHeight90 {
  max-height: 90vh;
}
.maxHeightScreen {
  max-height: 100vh;
}
.maxWidthFull {
  max-width: 100%;
}
.maxWidth90 {
  max-width: 90vw;
}
.maxWidthScreen {
  max-width: 100vw;
}
.HeightFull {
  height: 100%;
}
.Height90 {
  height: 90vh;
}
.HeightScreen {
  height: 100vh;
}
.WidthFull {
  width: 100%;
}
.Width90 {
  width: 90vw;
}
.WidthScreen {
  width: 100vw;
}

.textCenter {
  text-align: center;
}

.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px;
}
.my5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mx5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mx10 {
  margin-left: 10px;
  margin-right: 10px;
}
