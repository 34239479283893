.tbl,
.tbl td,
.tbl th {
  border: 1px solid;
  padding: 5px 20px;
}

.tbl {
  max-width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  overflow: auto;
}
