.allTestSeries_mainDiv {
  padding: 10px;
}
.allTestSeriesTitle {
  text-align: center;
  margin: 15px auto;
  /* display: block;
  width: 100vw; */
}
.allTestSeriesFirstSection {
  display: flex;
  justify-content: end;
  width: 100%;
  /* width: 100vw; */
  padding: 20px;
}

.createTestSeriesPopup {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  bottom: 20px;
  left: 20px;
  border: 2px solid rgb(0, 0, 0, 0.25);
  /* backdrop-filter: blur(10px); */
  background-color: white;
  /* background-color: rgba(130, 255, 76, 0.1); */
  /* background-color: rgb(0, 0, 0, 0.1); */
  /* background-color: rgb(250, 250, 250, 0.9); */
  border-radius: 5px;
  overflow: auto;
  padding-bottom: 60px;
}

.allTestSeries_allTestSeriesSection {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 20px;
}
