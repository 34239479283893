/* .selectInQuestion {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.selectInQuestion option {
  padding: 10px;
  border-radius: 0;
} */

.selectInQuestion {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
  outline: none;
  cursor: pointer;
  text-align: center;
}

.selectInQuestion option {
  padding: 8px;
}

.selectInOption {
  padding: 10px 0;
  font-size: 11px;
  margin-left: 2px;
  width: 64px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  text-align: center;
}
