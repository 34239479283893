.login_main_parent_div {
  /* width: 100vw; */
  background-color: #edf1f4;
  height: calc(100vh - 60px);
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}

.login_main_div {
  width: 300px;
  height: 400px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1), -15px -15px 20px #fffb;
  border-radius: 20px;
  gap: 10px;
  padding: 50px 20px 50px;
  /* padding: 50px auto; */
}
.login_header {
  text-align: left;
  width: 225px;
  margin-bottom: 30px;
}
.login-top-h1 {
  font-size: 2em;
  font-weight: 700;
  color: #000;
  /* margin-top: 10px; */
  /* text-align: center; */
}
.login-top-h1 span {
  font-size: 0.25em;
  font-weight: 300;
}
.user_name_div,
.user_password_div {
  position: relative;
  width: 225px;
  margin-bottom: 10px;
}
.user_name_div input[type="text"],
.user_password_div input[type="password"] {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1), inset -5px -5px 10px #fff;
  border-radius: 10px;
  padding-left: 30px;
}
.user_name_div .login_fields_icon,
.user_password_div .login_fields_icon {
  position: absolute;
  left: 10px;
  top: 30%;
}
.forgot_password_div {
  display: flex;
  justify-content: flex-end;
}
.forgot_password_div span {
  font-size: 0.75em;
  font-weight: 300;
  color: rgb(0, 0, 197);
}
.submit_button {
  width: 225px;
  position: relative;
}
.login_button {
  margin-top: 35px;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 255, 0.3);
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25), -5px -5px 10px #fff;
}

.login_button span {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
}

.login_button:active {
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
    inset -5px -5px 10px #aeb1e6;

  scale: 0.95;
}
.login_button:active span {
  font-size: 18px;
  font-weight: 600;
}
