.loaderDesignSpan {
  box-sizing: border-box;
  position: relative;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: relative;
  top: 2px;
}
.loaderDesignSpan:after,
.loaderDesignSpan:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background: #ff3d00;
  animation: spin 1s linear infinite;
  transform-origin: 0px 100%;
  /* transform-origin: 0 -10%; */
}
.loaderDesignSpan:before {
  transform-origin: 0 50%;
  background: #0400ff;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loaderLoadingSpan {
  margin-left: 40px;
  font-size: 30px;
  font-weight: 600;
  animation: loadingSpan 1.5s linear infinite;
}
@keyframes loadingSpan {
  0% {
    opacity: 0.3;
  }
  10% {
    opacity: 0.5;
  }
  35% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}
